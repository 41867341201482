var isHidden = false,
	iWidth = 0,
	sPrevOverflow = '',
	sPrevMargin = '';

export default {
	selector: '.js-scrollbar-offset',

	hide: function ()
	{
		if (isHidden)
		{
			return false;
		}

		isHidden = true;


		iWidth = this.width();

		var nHTML = document.documentElement,
			sMarginProp = 'marginRight';

		sPrevOverflow = nHTML.style.overflow;
		sPrevMargin = nHTML.style[sMarginProp];

		nHTML.style.overflow = 'hidden';
		nHTML.style[sMarginProp] = iWidth + 'px';

		fix_elements(this.selector, sMarginProp, iWidth + 'px');

		return true;
	},
	restore: function ()
	{
		if (!isHidden)
		{
			return false;
		}

		isHidden = false;


		var nHTML = document.documentElement,
			sMarginProp = 'marginRight';

		nHTML.style.overflow = sPrevOverflow;
		nHTML.style[sMarginProp] = sPrevMargin;

		sPrevOverflow = '';
		sPrevMargin = '';

		fix_elements(this.selector, sMarginProp, '');

		return true;
	},
	width: function ()
	{
		return Math.round(window.innerWidth - document.documentElement.clientWidth);
	}
};

function fix_elements (_sSelector, _sMarginProp, _xValue)
{
	var anNeedOffset = document.querySelectorAll(_sSelector),
		nElem,
		i, L;

	for (i = 0, L = anNeedOffset.length; i < L; i++)
	{
		nElem = anNeedOffset[i];

		nElem.style[_sMarginProp] = _xValue;
	}
};
